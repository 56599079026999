@font-face {
  font-family: rutledge;
  src: url(/static/media/rutledge-regular.feb00c54.ttf);
}

body {
  margin: 0;
  padding: 0;
  font-family: "rutledge" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "rutledge" !important;
}

a button .hbolegacyuser {
  text-decoration: none !important;
}
/* IMPORTS */


/* CSS OVERWRITE ------------------------------------------------------------------------------*/
.Edit {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.HeaderBar-title-3 {
  margin-bottom: 0px;
}

.kite-input {
  width: 100% !important;
  max-width: none !important;
}

.react-swipeable-view-container {
  /* AuthN XML textfield height */
  height: 45em;
}

pre {
  margin-bottom: 0 !important;
}

/* class starts with and all children of that class */
[class*="MuiTabs-indicator"] {
  background-color: #0173D1 !important;
}

[class*="MuiTab-label"] {
  color: #0173D1 !important;
} */

[class*="jss"] {
  color: #0173D1 !important;
}

[class*="MuiButtonBase-disabled-"] {  
  color: #A0B6C6 !important;
} 

/* IDs ------------------------------------------------------------------------------ */
#assetId {
  margin: 0px !important;
  border-radius: 4px !important;
  border-color: hsl(0,0%,80%) !important;
}

#assetId:disabled {
  background: #F2F2F2;
}

#auth-z-textfield {
  height: 17em;
}

#auth-n-textfield{
  height: 40em;
}

/* CLASSES  ------------------------------------------------------------------------------ */

/* Containers -------------------- */
.flex-container-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; 
}

.flex-container-row-no-center {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.flex-container-row-between {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.flex-container-column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; 
}

.flex-container-column-start {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; 
  -webkit-align-items: center; 
          align-items: center; 
}

.flex-start {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}  

.flex-end {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}  

/* Container children alignments -- */
.align-center {
  -webkit-align-self: center;
          align-self: center;
}

.align-start {
  -webkit-align-self: flex-start;
          align-self: flex-start;
}

.align-end {
  -webkit-align-self: flex-end;
          align-self: flex-end;
}


/* Padding -------------------- */
.padding-25 {
  padding: .25em;
}

.padding-5 {
  padding: .5em;
}

.padding-left-25 {
  padding-left: .25em;
}

.padding-right-25 {
  padding-right: .25em;
}

.padding-right-5 {
  padding-right: .5em;
}

.padding-top-1 {
  padding-top: 1em;
}

.padding-top-2 {
  padding-top: 2em;
}

.padding-top-10 {
  padding-top: 10em;
}

.spacer-1 {
  padding-left: .5em;
  padding-right: .5em;
}

.spacer-35 {
  padding-left: 17.5em;
  padding-right: 17.5em;
}

.margin-top-9-2 {
  padding-top: 9.2em;
}

.margin-5 {
  margin: .5em;
}

/* Style Classes -------------------- */
.App {
  width: 100%;
}

.bold {
  font-weight: bold;
}

.border {
  border-style: solid;
  border-width: 3px;
}

.button-container {
  position: relative;
  margin-top: 20px;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  width: 100%;
}

.button-container-xml {
  position: fixed;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  width: 100%;
  bottom: 0;
  right: 2em;
  padding-bottom: 3em;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.changelog-content {
  padding: 2.5em;
  margin-bottom: 60px;
}

.changelog-link {
  text-decoration: none;
}

.changelog-link:hover {
  text-decoration: none;
}

.checkbox-container {
  padding-bottom: 1.5em;
  height: 1em;
}

.clickable {
  cursor: pointer;
}

.click-link-color {
  color: #4a90e2;
  font-size: .9em;
  font-weight: 500;
  text-transform: uppercase;
  font-family: "rutledge" !important;
}

.loading {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: block;
  opacity: 1;
  background-color: #324c5a;
  z-index: 99;
  text-align: center;
}

.loading-middle {
  position: absolute;
  top: 40%;
  left: 42.5%;
  z-index: 100;
  color: #ffffff;
}

.light-blue { 
  color: #4a90e2 !important;
}

.makeSticky {
  position: fixed;
}

.margin-for-header {
  padding-top: 61px;
  margin-bottom: 61px;
}

.none {
  display: none !important;
}

.random-link-container {
  padding: 6px 8px;
}

.random-link-container:hover {
  background-color: rgba(63, 81, 181, 0.08);
  border-radius: 4px;
}

.response-table-resource {
  font-weight: bold;  
  padding: .25em .5em;
}

.response-table-th-key {  
  border: solid 1px #d8dde6;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #000000;
  padding: .25em;
}  

.response-table-th-value {
  border: solid 1px #d8dde6;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #000000;
  padding: .25em;
}

.results-text-area {
  width: 100%;
}

.results-text-area-header {
  width: 100%;
}

.settingsAddIcon {  
  padding-left: 2em;
  color: #63738A;
  font-weight: 600;
}

.settings-sidebar { 
  padding-top: 1.5em;   
  width: 10em;
}

.settings-card-container {
  width: 100%;
  margin-bottom: 75px;
}

.settings-single-card {
  width: 100%;
}

.settings-card-row { 
  width: 100%;
  height: 3em;
  border: solid 1px #d8dde6;
  background-color: #ffffff; 
}

.text-center {
  text-align: center;
}

.text-line-through {
  text-decoration: line-through;
}

.width-50 {
  width: 50%;
}

.width-100 {
  width: 100%;
}

.xml-display-panel {
  height: 95%;
  overflow: auto;
  font-family: "rutledge" !important;
  font-size: medium;
  /* background-color: #63738A; */
}

.xml-edit-panel {
  margin: 0;
  height: 100%;
  width: 100%;
}

.assert-icon-container {
  display: inline-block;
  height: 16px;
}

header a, header a:hover {
  text-decoration: none;
}
header .kite-btn.hbolegacyuser {
  height: 34px;
  padding: 8px 8px;
}
header .kite-btn-primary.hbolegacyuser {
  background-color: transparent;
  border: 1px solid #9e9e9e;
  border-radius: .25rem;
}
header .kite-btn-primary.hbolegacyuser:focus,
header .kite-btn-primary.hbolegacyuser:hover {
  background-color: transparent;
}
