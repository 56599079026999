@font-face {
  font-family: rutledge;
  src: url('../assets/fonts/rutledge-regular.ttf');
}

body {
  margin: 0;
  padding: 0;
  font-family: "rutledge" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "rutledge" !important;
}

a button .hbolegacyuser {
  text-decoration: none !important;
}